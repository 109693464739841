import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useUser} from "../adminLayout";
import {ChildData, UserInterface, UserRolesEnum} from "../../../utilities/constants";
import userService from "../../../../services/userService";
import {Button, Col, Container, Form, Modal, Row} from 'react-bootstrap';

function User(){
    let navigate = useNavigate();
    const [initialUserState, setInitialUserState] = useState<UserInterface>( {
        id: '',
        email: '',
        created_at: '',
        updated_at: '',
        role: 0,
        first_name: '',
        last_name: '',
        phone_number: '',
        address: '',
        zip_code: '',
        city: '',
        country: '',
        is_active: 0,
        role_name: '',
        localized_role_name: '',
        resource_url: '',
        children_url: '',
        scholars_url: '',
        set_active_url: '',
        delete_url: ''
    });
    const [formData, setFormData] = useState<UserInterface>( {
        id: '',
        email: '',
        created_at: '',
        updated_at: '',
        role: 0,
        first_name: '',
        last_name: '',
        phone_number: '',
        address: '',
        zip_code: '',
        city: '',
        country: '',
        is_active: 0,
        role_name: '',
        localized_role_name: '',
        resource_url: '',
        children_url: '',
        scholars_url: '',
        set_active_url: '',
        delete_url: ''
    });
    const [errors, setErrors] = useState({
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        zip_code: "",
        city: "",
        country: ""
    });

    const [childInitialUserState, setChildInitialUserState] = useState<ChildData>( {
        id: '',
        first_name: '',
        last_name: '',
        birthday: '',
        parent_1: '',
        parent_2: '',
        created_by: '',
        modified_by: '',
        teacher_1: '',
        teacher_2: '',
        is_active: 0,
        resource_url: '',
        child_notes_url: '',
        parent_1_url: '',
        teacher_1_url: '',
        set_inactive_url: '',
        set_active_url: '',
        delete_url: ''
    });
    const [childFormData, setChildFormData] = useState<ChildData>({
        id: '',
        first_name: '',
        last_name: '',
        birthday: '',
        parent_1: '',
        parent_2: '',
        created_by: '',
        modified_by: '',
        teacher_1: '',
        teacher_2: '',
        is_active: 0,
        resource_url: '',
        child_notes_url: '',
        parent_1_url: '',
        teacher_1_url: '',
        set_inactive_url: '',
        set_active_url: '',
        delete_url: ''
    })
    const [childErrors, setChildErrors] = useState({
        first_name: '',
        last_name: '',
        birthday: '',
        parent_1: '',
        parent_2: '',
        teacher_1: '',
        teacher_2: ''
    })

    const [user, setUser] = useState("");
    const [userId, setUserId] = useState('');
    const [roleUser, setRoleUser] = useState<UserRolesEnum>(UserRolesEnum.Director);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');
    const location = useLocation();
    const loggedUser = useUser()
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [pageToNavigate, setPageToNavigate] = useState('');
    const [getUserInfoCompleted, setGetUserInfoCompleted] = useState(false);
    const [getUserFromUrlCompleted, setGetUserFromUrlCompleted] = useState(false);
    const [showIsActiveFlag, setShowIsActiveFlag] = useState(true);

    const fetchInitialData = async () => {
        try {
            let response;
            if (location.pathname.includes('/user')){
                response = await userService.getUser(user);
                setRoleUser(response.data.role_name);
                setShowIsActiveFlag(true);
            } else if (location.pathname.includes('/child')) {
                response = await userService.getChild(user);
                setRoleUser(UserRolesEnum.Child);
                setShowIsActiveFlag(true);
            }
            else {
                response = await userService.getUser();
                setShowIsActiveFlag(false);
            }
            if (response.status === 200){
                if (location.pathname.includes('/child')) {
                    setChildInitialUserState(response.data);
                    setChildFormData(response.data);
                } else {
                    setInitialUserState(response.data); // Memorizza i risultati della ricerca nello stato
                    setFormData(response.data);
                }
                console.log(response);
            } else {
                console.error(response);
                if (response.status === 404) {
                    alert("Utente non a database")
                }
                navigate("/admin");
                window.location.reload();
            }
        } catch (error) {
            console.error('Errore durante il recupero dell\'utente:', error);
        }
    };
    // Prima useEffect per fare GetUserInfo
    useEffect(() => {
        getUserInfo().then(() => {
            setGetUserInfoCompleted(true);
        });
    }, []);
    // Seconda useEffect per settare User da location.pathname
    useEffect(() => {
        if (location.pathname.includes('/user')) {
            setUser(location.pathname.slice(location.pathname.lastIndexOf("/") + 1, location.pathname.length));
        } else if (location.pathname.includes('/child')) {
            setUser(location.pathname.slice(location.pathname.lastIndexOf("/") + 1, location.pathname.length));
        }
        setGetUserFromUrlCompleted(true);
    }, [])

    useEffect(() => {
        if (getUserInfoCompleted && getUserFromUrlCompleted) {
            fetchInitialData();
        }
    }, [getUserInfoCompleted, getUserFromUrlCompleted]);

    const getUserInfo = async () => {
        try {
            const userInfo = await userService.getUserInfo();
            setUserId(userInfo.id);
        } catch (error) {
            console.error('Errore durante il recupero delle informazioni utente:', error);
        }
    };

    // Decommenta per DEBUG
    /*useEffect(() => {
        console.log(formData);
    }, [formData])*/

    const handleChange = (e) => {
        console.log(e.target.name)
        if (location.pathname.includes('/child')) {
            setChildErrors( {...childErrors, [e.target.name] : '' });
            if (e.target.name === "is_active") {
                setChildFormData({...childFormData, [e.target.name]: e.target.checked ? 1 : 0 });
            } else {
                setChildFormData({ ...childFormData, [e.target.name]: e.target.value });
            }
        } else {
            setErrors( {...errors, [e.target.name] : '' });
            if (e.target.name === "is_active") {
                setFormData({...formData, [e.target.name]: e.target.checked ? 1 : 0 });
            } else {
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (location.pathname.includes('/child')) {
            // Trova i campi che sono stati modificati
            const modifiedFields: Partial<ChildData> = {};
            for (const key in childFormData) {
                if (childFormData[key] !== childInitialUserState[key]) {
                    modifiedFields[key] = childFormData[key];
                }
            }
            // Qui puoi implementare il codice per inviare le informazioni al backend, se necessario
            try {
                const response: ChildData = await userService.updateChild(user, modifiedFields);
                fetchInitialData()
                setMessage("Modifiche salvate con successo");
                setMessageClass("alert-success");
                setShowMessage(true); // Attiva il messaggio di successo
                setTimeout(() => {
                    setShowMessage(false); // Disattiva il messaggio di successo dopo 5 secondi
                }, 5000);
                console.log('Informazioni utente modificate:', formData);
            } catch (error: any) {
                if (error.status === 403) {
                    console.error(error.statusText);
                } else if (error.status === 422) {
                    const responseErrors: {
                        first_name: string,
                        last_name: string,
                        birthday: string,
                        parent_1: string,
                        parent_2: string,
                        teacher_1: string,
                        teacher_2: string
                    } = childErrors
                    Object.keys(error.data.errors).forEach(key => {
                        responseErrors[key] = error.data.errors[key][0]
                    })
                    setChildErrors(responseErrors);
                }
                setMessage("Modifiche non salvate - " + error.statusText);
                setMessageClass("alert-danger");
                setShowMessage(true); // Attiva il messaggio di successo
                setTimeout(() => {
                    setShowMessage(false); // Disattiva il messaggio di successo dopo 5 secondi
                }, 5000);
                console.error('Errore durante l\'aggiornamento dell\'utente:', error);
            }
        } else {
            // Trova i campi che sono stati modificati
            const modifiedFields: Partial<UserInterface> = {};
            for (const key in formData) {
                if (formData[key] !== initialUserState[key]) {
                    modifiedFields[key] = formData[key];
                }
            }
            // Qui puoi implementare il codice per inviare le informazioni al backend, se necessario
            try {
                const response: UserInterface = await userService.updateUser(user !== "" ? user : userId, modifiedFields);
                fetchInitialData()
                setMessage("Modifiche salvate con successo");
                setMessageClass("alert-success");
                setShowMessage(true); // Attiva il messaggio di successo
                setTimeout(() => {
                    setShowMessage(false); // Disattiva il messaggio di successo dopo 5 secondi
                }, 5000);
                console.log('Informazioni utente modificate:', formData);
            } catch (error: any) {
                if (error.status === 403) {
                    console.error(error.statusText);
                } else if (error.status === 422) {
                    const responseErrors: {
                        email: string,
                        firstName: string,
                        lastName: string,
                        phone: string,
                        address: string,
                        zip_code: string,
                        city: string,
                        country: string
                    } = errors
                    Object.keys(error.data.errors).forEach(key => {
                        switch (key) {
                            case "email":
                                responseErrors.email = error.data.errors[key][0];
                                break;
                            case "first_name":
                                responseErrors.firstName = error.data.errors[key][0];
                                break;
                            case "last_name":
                                responseErrors.lastName = error.data.errors[key][0];
                                break;
                            case "phone_number":
                                responseErrors.phone = error.data.errors[key][0];
                                break;
                            case "address":
                                responseErrors.address = error.data.errors[key][0];
                                break;
                            case "zip_code":
                                responseErrors.zip_code = error.data.errors[key][0];
                                break;
                            case "city":
                                responseErrors.city = error.data.errors[key][0];
                                break;
                            case "country":
                                responseErrors.country = error.data.errors[key][0];
                                break;
                            default:
                                responseErrors[key] = error.data.errors[key][0]
                                break;
                        }
                    })
                    setErrors(responseErrors);
                }
                setMessage("Modifiche non salvate - " + error.statusText);
                setMessageClass("alert-danger");
                setShowMessage(true); // Attiva il messaggio di successo
                setTimeout(() => {
                    setShowMessage(false); // Disattiva il messaggio di successo dopo 5 secondi
                }, 5000);
                console.error('Errore durante l\'aggiornamento dell\'utente:', error);
            }
        }

    };

    const handleDeleteUser = async (e) => {
        e.preventDefault();
        // Qui puoi implementare il codice per cancellare l'utente
        try {
            const response = await userService.deleteUser(user);
            if (response.status === 200) {
                setModalTitle('Utente cancellato');
                setModalBody('L\'utente è stato cancellato con successo.\n');
                setShowModal(true);
                console.log(response);
                console.log('Utente cancellato');
                setPageToNavigate('/admin');
            } else if (response.status === 403 || response.status === 404) {
                setModalTitle('Errore nell\'eliminazione');
                setModalBody(response.data?.message);
                setShowModal(true);
                console.error(response);
                setPageToNavigate('/admin');
            } else if (response.status === 405) {
                setModalTitle('Errore nell\'eliminazione');
                setModalBody(response.data?.message);
                setShowModal(true);
                console.error(response);
            } else {
                if (response.data?.message.includes("Integrity constraint violation: 19 FOREIGN KEY constraint failed")) {
                    setModalTitle('Errore nell\'eliminazione');
                    setModalBody("Non è possibile eliminare questo utente perché sono associati altri utenti");
                    setShowModal(true);
                } else {
                    setModalTitle('Errore nell\'eliminazione');
                    setModalBody(response.data?.message);
                    setShowModal(true);
                    setPageToNavigate('/admin');
                }
                console.error(response);
            }
        } catch (error) {
            console.error('Errore durante l\'eliminazione dell\'utente:', error);
        }
    };

    const handleDeleteChild = async (e) => {
        e.preventDefault();
        // Qui puoi implementare il codice per cancellare il bambino
        try {
            const response = await userService.deleteChild(user);
            if (response.status === 200) {
                setModalTitle('Bambino cancellato');
                setModalBody('Il bambino è stato cancellato con successo.\n');
                setShowModal(true);
                console.log(response);
                console.log('Bambino cancellato');
                setPageToNavigate('/admin');
            } else if (response.status === 403 || response.status === 404) {
                setModalTitle('Errore nell\'eliminazione');
                setModalBody(response.data?.message);
                setShowModal(true);
                console.error(response);
                setPageToNavigate('/admin');
            } else if (response.status === 405) {
                setModalTitle('Errore nell\'eliminazione');
                setModalBody(response.data?.message);
                setShowModal(true);
                console.error(response);
            } else {
                if (response.data?.message.includes("Integrity constraint violation: 19 FOREIGN KEY constraint failed")) {
                    setModalTitle('Errore nell\'eliminazione');
                    setModalBody("Non è possibile eliminare questo utente perché sono associati altri utenti");
                    setShowModal(true);
                } else {
                    setModalTitle('Errore nell\'eliminazione');
                    setModalBody(response.data?.message);
                    setShowModal(true);
                    setPageToNavigate('/admin');
                }
                console.error(response);
            }
        } catch (error) {
            console.error('Errore durante l\'eliminazione dell\'utente:', error);
        }
    };

    const handleCloseModal = () => {
        if (pageToNavigate === "/admin"){
            navigate("/admin");
            window.location.reload();
        }
        setShowModal(false);
    }

    return(
        <>
            <Container>
                <Row>
                    <Col>
                        <h2>Profilo Utente</h2>
                        <Form onSubmit={handleSubmit}>
                            {roleUser !== UserRolesEnum.Child && (
                                <>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={formData?.email || ''}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formFirstName">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="first_name"
                                            value={formData?.first_name || ''}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.firstName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formLastName">
                                        <Form.Label>Cognome</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="last_name"
                                            value={formData?.last_name || ''}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.lastName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formPhoneNumber">
                                        <Form.Label>Numero di telefono</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="phone_number"
                                            value={formData?.phone_number || ''}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.phone}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formAddress">
                                        <Form.Label>Indirizzo</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="address"
                                            value={formData?.address || ''}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.address}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Row>
                                        <Form.Group as={Col} className="mb-3" controlId="formZipCode">
                                            <Form.Label>CAP</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="zip_code"
                                                value={formData?.zip_code || ''}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.zip_code}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} className="mb-3" controlId="formCity">
                                            <Form.Label>Città</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="city"
                                                value={formData?.city || ''}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.city}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Form.Group className="mb-3" controlId="formCountry">
                                        <Form.Label>Paese</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="country"
                                            value={formData?.country || ''}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.country}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </>
                            )}
                            {roleUser === UserRolesEnum.Child && (
                                <>
                                    <Form.Group className="mb-3" controlId="formFirstName">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="first_name"
                                            value={childFormData?.first_name || ''}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {childErrors.first_name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formLastName">
                                        <Form.Label>Cognome</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="last_name"
                                            value={childFormData?.last_name || ''}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {childErrors.last_name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBirthday">
                                        <Form.Label>Compleanno</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="birthday"
                                            value={childFormData?.birthday || ''}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {childErrors.birthday}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {/*birthday
                                    parent_1
                                    parent_2
                                    teacher_1
                                    teacher_2*/}
                                </>
                            )}

                            {showIsActiveFlag && roleUser !== UserRolesEnum.Child &&
                                <Form.Group className="mb-3" controlId="formIsActive">
                                    <Form.Check
                                        type="checkbox"
                                        label={<span style={{ fontWeight: 'bold' }}>{formData?.is_active === 1 ? "Attivo" : "Disattivo"}</span>}
                                        name="is_active"
                                        checked={formData?.is_active === 1}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            }
                            {showIsActiveFlag && roleUser === UserRolesEnum.Child &&
                                <Form.Group className="mb-3" controlId="formIsActive">
                                    <Form.Check
                                        type="checkbox"
                                        label={<span style={{ fontWeight: 'bold' }}>{childFormData?.is_active === 1 ? "Attivo" : "Disattivo"}</span>}
                                        name="is_active"
                                        checked={childFormData?.is_active === 1}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            }

                            <div className="mb-3">
                                {showMessage && (
                                    <div className={"alert " + messageClass} role="alert">
                                        {message}
                                    </div>
                                )}
                            </div>
                            <div className="d-flex justify-content-between">
                                <Button variant="primary" type="submit">
                                    Salva modifiche
                                </Button>
                                {(location.pathname.includes('/user')) && (
                                    <Button variant="danger" onClick={handleDeleteUser}>
                                        Cancella utente
                                    </Button>
                                )}
                                {roleUser === UserRolesEnum.Child && (
                                    <Button variant="danger" onClick={handleDeleteChild}>
                                        Cancella bambino
                                    </Button>
                                )}
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalBody}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseModal}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default User;